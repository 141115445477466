// 'app-' prefix is required because is use in AngularController.java if user refresh page forward to index.html all page 'app-**'

export enum Step {
  HOME_PAGE = '/',
  LANDING_PAGE = 'app-landing-page',
  SHORT_PATH = 'app-short-path',
  VEHICLE = 'app-vehicle',
  VEHICLE_ADDITIONAL_INFO = 'app-vehicle-additional-info',
  VEHICLE_OWNER = 'app-vehicle-owner',
  VEHICLE_OWNER_DETAILS = 'app-vehicle-owner-details',
  VEHICLE_FIRST_CO_OWNER_DETAILS = 'app-vehicle-first-co-owner-details',
  VEHICLE_SECOND_CO_OWNER_DETAILS = 'app-vehicle-second-co-owner-details',
  INSURANCE_START_DATE = 'app-insurance-start-date',
  SESSION_EXPIRED = 'app-session-expired',
}

export enum OfferStep {
  OMNI_OFFER = 'app-omni-offer',
  OMNI_ADDITIONAL_OPTION = 'app-omni-additional-option',
  INSURANCE_VARIANT = 'app-insurance-variant',
  INSURANCE_ADDITIONAL_OPTION = 'app-insurance-additional-option',
  YOUR_OFFER = 'app-your-offer',
  INSURANCE_TOTAL_PRICE = 'app-insurance-total-price',
  VEHICLE_REGISTRATION_DATA = 'app-vehicle-registration-data',
  VEHICLE_OWNER_ADDITIONAL_DETAILS = 'app-vehicle-owner-additional-details',
  VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS = 'app-vehicle-first-co-owner-additional-details',
  VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS = 'app-vehicle-second-co-owner-additional-details',
  VEHICLE_OWNER_ADDITIONAL_ADDRESS = 'app-vehicle-owner-additional-address',
  VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS = 'app-vehicle-first-co-owner-additional-address',
  VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS = 'app-vehicle-second-co-owner-additional-address',
  CONTACT_AND_CONSENT = 'app-contact-and-consent',
  INCONSISTENT_UFG = 'app-inconsistent-ufg',
  INCONSISTENT_TOTAL_PRICE = 'app-inconsistent-total-price',
  SHORT_PATH_DETAILS = 'app-short-path-details',
  THANK_YOU = 'app-thank-you',
  THANK_YOU_FOR_PAYMENT = 'app-thank-you-for-payment',
}
