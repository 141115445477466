import {Component} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-correct-data-dialog',
  templateUrl: './correct-data-dialog.component.html',
  styleUrls: [
    '../../../scss/shared/dialog.scss',
    'correct-data-dialog.component.scss'
  ],
})
export class CorrectDataDialogComponent {

  constructor(private dialogRef: MatDialogRef<any>) {
  }

  close(): void {
    this.dialogRef.close({ goToDeclarationPath: false });
  }

  goNext(): void {
    this.dialogRef.close({ goToDeclarationPath: true });
  }
}
