import {Injectable} from '@angular/core';
import {OfferStep, Step} from '@app/shared/models/step/step.model';
import {NavigationEnd} from '@angular/router';
import {StepResolver} from '@app/shared/services/url/step-resolver';
import {CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';
import {InputMode} from '@app/landing-page/landing-page.model';
import {BreadcrumbContext} from './breadcrumb-context.enum';
import {BreadcrumbStep} from './breadcrumb-step.enum';
import {BreadcrumbStepData} from './breadcrumb-step-data';
import {
  BREADCRUMB_LABELS_OFFER,
  BREADCRUMB_LABELS_OFFER_YOUR_OFFER,
  BREADCRUMB_LABELS_OMNI,
  BREADCRUMB_LABELS_OMNI_YOUR_OFFER,
  BREADCRUMB_LABELS_SHORT_PATH,
  BREADCRUMB_LABELS_SHORT_PATH_YOUR_OFFER,
  BREADCRUMB_LABELS_VEHICLE,
  BREADCRUMB_LABELS_VEHICLE_YOUR_OFFER
} from './breadcrumb-labels.const';
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {Flags} from "@app/shared/models/flag/flag.model";

@Injectable()
export class BreadcrumbService {
  private readonly breadcrumbs: Record<BreadcrumbContext, Map<Step | OfferStep, BreadcrumbStep>> = {
    [BreadcrumbContext.ShortPath]: new Map([
      [Step.SHORT_PATH, BreadcrumbStep.SHORT_PATH_BASIC_DATA],
      [Step.INSURANCE_START_DATE, BreadcrumbStep.SHORT_PATH_CONSENTS],
    ]),
    [BreadcrumbContext.Declaration]: new Map([
      [Step.VEHICLE, BreadcrumbStep.VEHICLE_BASIC_DATA],
      [Step.VEHICLE_ADDITIONAL_INFO, BreadcrumbStep.VEHICLE_ADDITIONAL_DATA],
      [Step.VEHICLE_OWNER, BreadcrumbStep.VEHICLE_OWNER_DATA],
      [Step.VEHICLE_OWNER_DETAILS, BreadcrumbStep.VEHICLE_OWNER_DATA],
      [Step.VEHICLE_FIRST_CO_OWNER_DETAILS, BreadcrumbStep.VEHICLE_OWNER_DATA],
      [Step.VEHICLE_SECOND_CO_OWNER_DETAILS, BreadcrumbStep.VEHICLE_OWNER_DATA],
      [Step.INSURANCE_START_DATE, BreadcrumbStep.VEHICLE_CONSENTS],
    ]),
    [BreadcrumbContext.Offer]: new Map([
      [OfferStep.SHORT_PATH_DETAILS, BreadcrumbStep.SHORT_PATH_OFFER_DETAILS],
      [OfferStep.INSURANCE_VARIANT, BreadcrumbStep.OFFER_VARIANT],
      [OfferStep.INSURANCE_ADDITIONAL_OPTION, BreadcrumbStep.OFFER_ADDITIONAL_OPTIONS],
      [OfferStep.YOUR_OFFER, BreadcrumbStep.YOUR_OFFER],
      [OfferStep.VEHICLE_REGISTRATION_DATA, BreadcrumbStep.OFFER_TOTAL_PRICE],
      [OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS, BreadcrumbStep.OFFER_TOTAL_PRICE],
      [OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS, BreadcrumbStep.OFFER_TOTAL_PRICE],
      [OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS, BreadcrumbStep.OFFER_TOTAL_PRICE],
      [OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS, BreadcrumbStep.OFFER_TOTAL_PRICE],
      [OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS, BreadcrumbStep.OFFER_TOTAL_PRICE],
      [OfferStep.INSURANCE_TOTAL_PRICE, BreadcrumbStep.OFFER_TOTAL_PRICE],
      [OfferStep.CONTACT_AND_CONSENT, BreadcrumbStep.OFFER_PAYMENT],
    ]),
    [BreadcrumbContext.OMNI]: new Map([
      [OfferStep.OMNI_ADDITIONAL_OPTION, BreadcrumbStep.OMNI_ADDITIONAL_OPTION],
      [OfferStep.YOUR_OFFER, BreadcrumbStep.OMNI_YOUR_OFFER],
      [OfferStep.SHORT_PATH_DETAILS, BreadcrumbStep.OMNI_SHORT_PATH],
      [OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS, BreadcrumbStep.OMNI_SHORT_PATH],
      [OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS, BreadcrumbStep.OMNI_SHORT_PATH],
      [OfferStep.CONTACT_AND_CONSENT, BreadcrumbStep.OMNI_SELL],
    ]),
  };

  constructor(private storeService: CalculationStoreService,
              private featureFlagService: FeatureFlagService) {
  }

  getStep(navigation: NavigationEnd): BreadcrumbStep | undefined {
    const context = this.getCurrentContext(navigation);
    const step = StepResolver.getCurrentStepName(navigation.url) as
      | Step
      | OfferStep;

    return this.breadcrumbs[context].get(step);
  }

  getCurrentContext(routerEvent: NavigationEnd): BreadcrumbContext {
    const inputMode = this.storeService.getLandingPage()?.inputMode;

    if (inputMode === InputMode.OMNI) {
      return BreadcrumbContext.OMNI;
    } else if (this.isOfferContext(routerEvent)) {
      return BreadcrumbContext.Offer;
    } else if (inputMode === InputMode.CEPIK) {
      return BreadcrumbContext.ShortPath;
    }  else {
      return BreadcrumbContext.Declaration;
    }
  }

  private isOfferContext(navigation: NavigationEnd): boolean {
    const step = StepResolver.getCurrentStepName(navigation.url);

    return Object.values(OfferStep).includes(step as OfferStep);
  }

  getBreadcrumbsFor(type: BreadcrumbContext): BreadcrumbStepData[] {
    const flagYourOffer = this.featureFlagService.isActive(Flags.FLAG_1843_APK_YOUR_OFFER_VIEW);

    switch (type) {
      case BreadcrumbContext.ShortPath:
        return flagYourOffer ? BREADCRUMB_LABELS_SHORT_PATH_YOUR_OFFER : BREADCRUMB_LABELS_SHORT_PATH;

      case BreadcrumbContext.Declaration:
        return flagYourOffer ? BREADCRUMB_LABELS_VEHICLE_YOUR_OFFER : BREADCRUMB_LABELS_VEHICLE;

      case BreadcrumbContext.Offer:
        return flagYourOffer ? BREADCRUMB_LABELS_OFFER_YOUR_OFFER : BREADCRUMB_LABELS_OFFER;

      case BreadcrumbContext.OMNI:
        return flagYourOffer ? BREADCRUMB_LABELS_OMNI_YOUR_OFFER : BREADCRUMB_LABELS_OMNI;

      default:
        return [];
    }
  }
}
