import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {PrepareCalculationResponseModel} from '@app/calculate/calculate/prepare-calculation-response.model';
import {CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';
import {tap} from 'rxjs/operators';
import {InsuranceType} from '@app/calculate/vehicle/vehicle.model';
import {CalculationResponseModel} from '@app/calculate/calculate/calculation-response.model';
import {API_URL} from '@app/shared/constants/settings.constant';
import {CoverModel, InsuranceVariantModel} from './insurance-variant.model';
import {ModifyCalculationRequestModel} from '@app/calculate/calculate/modify-calculation-request.model';
import {ModifyCalculationResponseModel} from '@app/calculate/calculate/modify-calculation-response.model';
import {InputMode} from '@app/landing-page/landing-page.model';
import {PersonalDataStoreService} from '@app/shared/services/personal-data/personal-data-store.service';
import {PathType} from "@app/calculate/calculate/path-type";
import {CepikService} from "@app/shared/services/cepik/cepik.service";
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {Flags} from "@app/shared/models/flag/flag.model";

@Injectable()
export class CalculationService {

  constructor(private httpClient: HttpClient,
              private storeService: CalculationStoreService,
              private personalDataStoreService: PersonalDataStoreService,
              private featureFlagService: FeatureFlagService,
              private cepikService: CepikService) {
  }

  prepareCalculation(): Observable<PrepareCalculationResponseModel> {
    const calculationId = this.storeService.getCalculationId();
    const dictionary = this.storeService.getCalculationDictionary();

    if (!dictionary) {
      return this.httpClient.post<PrepareCalculationResponseModel>(
        `${API_URL}/calculation/prepare/${calculationId}`,
        null
      ).pipe(
          tap(prepareCalculationResult => {
            this.storeService.storeCalculationDictionary(prepareCalculationResult.dictionary);
          })
      );
    } else {
      return new Observable<PrepareCalculationResponseModel>(observer => {
        observer.next({
          dictionary: dictionary
        });
        observer.complete();
      });
    }
  }

  calculate(pathType?: PathType): Observable<CalculationResponseModel> {
    const calculationId = this.storeService.getCalculationId();
    const calculationForm = this.storeService.getCalculationForm();
    const savedCalculationForm = this.storeService.getSavedCalculationForm();
    const shouldSaveCalculation = this.shouldSaveCalculation(calculationForm, savedCalculationForm);

    const calculateUrl = this.getCalculateUrl(pathType || PathType.CLASSIC, calculationId, shouldSaveCalculation);

    return this.httpClient.post<CalculationResponseModel>(
      `${API_URL}/${calculateUrl}`,
      calculationForm
    ).pipe(
      tap(calculationResult => {
        this.storeService.storeCalculationResponse(calculationResult);
        this.storeService.storeOfferNumber(calculationResult.offerNumber);
        if (shouldSaveCalculation) {
          this.storeService.storeSavedCalculationForm(calculationForm);
        }
      })
    );
  }

  private getCalculateUrl(pathType: PathType, calculationId, shouldSaveCalculation): string {
    if (this.featureFlagService.isActive(Flags.FLAG_1894_NEW_VARIANTS_CONFIGURATION)) {
      return `calculation/calculateVariants/${calculationId}`;
    }

    if (pathType == PathType.CLASSIC) {
      return `calculation/${calculationId}/${shouldSaveCalculation}`;
    } else if (pathType == PathType.SHORT_PATH) {
      return `cepik/calculate/${calculationId}/${shouldSaveCalculation}`;
    }
  }

  private shouldSaveCalculation(calculationForm, savedCalculationForm): boolean {
    return JSON.stringify(calculationForm) !== JSON.stringify(savedCalculationForm);
  }

  saveCalculation(): Observable<CalculationResponseModel> {
    const calculationId = this.storeService.getCalculationId();
    const calculationForm = this.storeService.getCalculationForm();
    const savedCalculationForm = this.storeService.getSavedCalculationForm();
    const inputMode = this.storeService.getLandingPage().inputMode;

    if (this.shouldSaveCalculation(calculationForm, savedCalculationForm)) {
      this.storeService.storeSavedCalculationForm(calculationForm);

      if (inputMode === InputMode.CEPIK) {
        return  this.cepikService.saveCalculation(calculationId, calculationForm);
      } else {
        return this.httpClient.put<CalculationResponseModel>(`${API_URL}/calculation/${calculationId}/save`, calculationForm);
      }
    } else {
      return of(new CalculationResponseModel());
    }
  }

  modifyCalculation(insuranceVariant: InsuranceVariantModel): Observable<ModifyCalculationResponseModel> {
    return this.modifyCalculationWithAdditional(insuranceVariant, []);
  }

  modifyCalculationWithAdditional(insuranceVariant: InsuranceVariantModel, additionalCovers: CoverModel[]): Observable<ModifyCalculationResponseModel> {
    const calculationId = this.storeService.getCalculationId();
    const packageName = insuranceVariant.packageName;
    const name = insuranceVariant.name;
    const calculationForm = this.storeService.getCalculationForm();
    const covers = insuranceVariant.covers.concat(additionalCovers);
    const inputMode = this.storeService.getLandingPage().inputMode;
    const updatedCalculationForm = {...calculationForm, insuranceVariant}
    const params = new ModifyCalculationRequestModel(packageName, updatedCalculationForm, covers);
    let request: Observable<ModifyCalculationResponseModel>;

    if (inputMode === InputMode.CEPIK) {
      request = this.cepikService.modifyCalculation(calculationId, params);
    } else {
      request = this.httpClient.put<ModifyCalculationResponseModel>(`${API_URL}/calculation/modify/${calculationId}`, params);
    }

    return request.pipe(
        tap(response => {
          if (!response.variant.packageName) {
            response.variant.packageName = packageName;
          }

          if (!response.variant.name) {
            response.variant.name = name;
          }

          covers.map(userCover => {
            const matchingResponseCover = response.variant.covers.find(responseCover => responseCover.code === userCover.code);

            if (matchingResponseCover) {
              userCover.availableOptions = matchingResponseCover.availableOptions;
              userCover.optionId = matchingResponseCover.optionId;
              userCover.insuranceAmount = matchingResponseCover.insuranceAmount;
              userCover.excessAmount = matchingResponseCover.excessAmount;
              userCover.yearlyPremium = matchingResponseCover.yearlyPremium;

              return userCover;
            }
          });

          response.variant.covers = covers;
          response.variant.additionalCovers = insuranceVariant.additionalCovers

          const variants = this.storeService.getCalculationForm().variants;
          const modifiedVariants = variants.map(variant =>
            variant.name === response.variant.name ? {...response.variant, covers, additionalCovers: insuranceVariant.additionalCovers } : variant)
          this.storeService.storeInsuranceVariants(modifiedVariants);
          this.storeService.storeInsuranceVariant(response.variant);
        })
    );
  }

  isOcInsurance(): boolean {
    const vehicle = this.storeService.getVehicle();
    return vehicle == null ? false : vehicle.insuranceType === InsuranceType.OC;
  }

  isOcAcInsurance(): boolean {
    const vehicle = this.storeService.getVehicle();
    return vehicle == null ? false : vehicle.insuranceType === InsuranceType.OC_AND_AC;
  }

  isDeclarationPath(): boolean {
    return this.storeService.getLandingPage().inputMode === InputMode.DECLARATION;
  }
}
