import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CallbackModel} from '@app/shared/components/callback/callback.model';
import {CallbackService} from '@app/shared/components/callback/callback.service';
import {NgForm, NgModel} from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {CalculationService} from '@app/calculate/calculate/calculation.service';
import {CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';
import {GtmService} from '@app/shared/services/gtm/gtm.service';
import {CallbackAction} from '@app/shared/services/gtm/model/callback-action.model';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {PersonalDataStoreModel} from "@app/shared/services/personal-data/personal-data-store.model";
import {ErrorCode} from "@app/shared/interceptors/server-error/error-info.model";
import {CallbackLabel} from "@app/shared/services/gtm/model/callback-label.model";
import {CallbackTitle} from "@app/shared/components/callback/callback-title.model";
import * as dayjs from "dayjs";
import {ScrollToFirstInvalidService} from "@app/shared/directives/scroll-to-invalid/scroll-to-invalid.service";
import {CalendarService} from "@app/shared/services/calendar/calendar.service";
import {Flags} from "@app/shared/models/flag/flag.model";
import {ConsentService} from "@app/shared/services/consent/consent.service";
import {PolicyService} from "@app/offer/policy/policy-service";
import {OfferStep} from "@app/shared/models/step/step.model";
import {switchMap} from "rxjs/operators";
import {StepResolver} from "@app/shared/services/url/step-resolver";
import {Router} from "@angular/router";
import {Consent} from "@app/shared/services/consent/consent.model";
import {ConsentCode} from "@app/shared/services/consent/consent-code.model";

@Component({
  selector: 'callback-dialog',
  templateUrl: './callback-dialog.component.html',
  styleUrls: ['./callback-dialog.component.scss', '../../../../scss/shared/dialog.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallbackDialogComponent implements OnInit {

  callback: CallbackModel;
  showFormMobile = false;
  isCallCenterWorkTime = false;
  callbackTitle: string;
  callbackTitleClass: string;
  gtmLabel: string;
  mainUserPersonalData: PersonalDataStoreModel;
  insurerPersonalData: PersonalDataStoreModel;
  consent: Consent;

  constructor(private callbackService: CallbackService,
              private consentService: ConsentService,
              private policyService: PolicyService,
              private router: Router,
              private storeService: CalculationStoreService,
              private calculationService: CalculationService,
              private featureFlagService: FeatureFlagService,
              private gtmService: GtmService,
              private scrollToFirstInvalidService: ScrollToFirstInvalidService,
              private cdRef: ChangeDetectorRef,
              private calendarService: CalendarService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.callback = new CallbackModel();
    this.callback.businessType = this.callbackService.getBusinessType(data);
    this.setDefaultFields();
    this.setCustomFields(data);
    this.gtmService.emitCallbackEvent(CallbackAction.POPUP_DISPLAYED, this.gtmLabel);
  }

  async ngOnInit() {
    this.mainUserPersonalData = this.storeService.getMainVehicleUserDetails();
    this.insurerPersonalData = this.storeService.getInsurerData();
    this.isCallCenterWorkTime = this.checkIsCallCenterWorkTime();
    this.consent = await this.consentService.getConsent(ConsentCode.INFO_OBLIGATION_WITH_PROFILING);

    this.autoCompleteForm();
  }

  send(callbackForm: NgForm) {
    const self = this;

    if (callbackForm.invalid) { return; }

    if (!!this.storeService.getCalculationResponse()) {
      const currentStepName = <OfferStep>StepResolver.getCurrentStepName(this.router.url);
      const mainVehicleOwnerDetails = this.storeService.getMainVehicleUserDetails();
            mainVehicleOwnerDetails.firstName = this.callback.firstName;
            mainVehicleOwnerDetails.lastName = this.callback.lastName;
            mainVehicleOwnerDetails.phoneNumber = this.callback.phoneNumber;

      this.storeService.storeMainVehicleUserDetails(mainVehicleOwnerDetails).pipe(
        switchMap(() => this.calculationService.saveCalculation()),
        switchMap(() => this.policyService.prepareSell(currentStepName))
      ).subscribe(() => {
        this.data.closeDialogCallback();
        this.callbackSentEvent(self.gtmLabel);
      });
    } else {
      this.callbackService.createCallback(this.callback).subscribe(() => {
        this.data.closeDialogCallback();
        this.callbackSentEvent(self.gtmLabel);
      });
    }

    this.storeService.storeSavedCallbackForm(this.callback);
  }

  onConsentChange(checkboxChange: MatCheckboxChange) {
    this.gtmService.emitCallbackEvent(checkboxChange.checked ?
      CallbackAction.FORM_FIELD_CONSENT_CHECKED : CallbackAction.FORM_FIELD_CONSENT_UNCHECKED,
      checkboxChange.source._elementRef.nativeElement.innerText);
  }

  callbackSentEvent(label: string) {
    this.gtmService.emitCallbackEvent(CallbackAction.FORM_SENT, label);
  }

  callbackFieldFocusIn(field: NgModel) {
    this.gtmService.emitCallbackEvent(CallbackAction.FORM_FIELD_ACTIVATED, field.name);
  }

  callbackFieldFocusOut(field: NgModel) {
    const isValid = field.valid;
    const isEmpty = !field.value || field.value.length === 0;

    if (isEmpty) {
      this.gtmService.emitCallbackEvent(CallbackAction.FORM_FIELD_LEFT_EMPTY, field.name);
    } else {
      if (isValid) {
        this.gtmService.emitCallbackEvent(CallbackAction.FORM_FIELD_COMPLETED_CORRECTLY, field.name);
      } else {
        const fieldName = `${field.name} - ${this.getFieldErrors(field)}`;
        this.gtmService.emitCallbackEvent(CallbackAction.FORM_FIELD_COMPLETED_INCORRECTLY, fieldName);
      }
    }
  }

  closeIconDialog(): void {
    this.data.closeDialogCallback();
  }

  private getFieldErrors(field: NgModel): string {
    return Object.keys(field.errors)
      .map(error => {
        if (error === 'minlength') {
          return 'Minimalna liczba znaków wynosi: ' + field.errors['minlength']['requiredLength'];
        }

        return error;
      })
      .join('; ');
  }

  isFirstNameAutoFocus(): boolean {
    return this.mainUserPersonalData?.firstName ? false : true;
  }

  isNextButtonAutoFocus(): boolean {
    return this.mainUserPersonalData?.firstName ? true : false;
  }

  leavePhoneOnMobile($event) {
    $event.preventDefault();

    this.showFormMobile = true;
    this.cdRef.detectChanges();
    const firstNameInput: HTMLElement = document.querySelector('#firstName-callback-first-name');
    this.scrollToFirstInvalidService.scrollToElement(firstNameInput);
    firstNameInput.focus();
  }

  private checkIsCallCenterWorkTime(): boolean {
    const currentDate = new Date();
    const currentHour = dayjs(currentDate).hour();
    const isBusinessDay = this.calendarService.isBusinessDay(currentDate);
    const isHoliday = this.calendarService.isHoliday(currentDate);

    return currentHour >= 8 && currentHour <= 20 && isBusinessDay && !isHoliday;
  }

  private autoCompleteForm() {
    const currentUserPersonalData = this.insurerPersonalData ?? this.mainUserPersonalData;

    this.callback.phoneNumber = currentUserPersonalData?.phoneNumber;
    this.callback.firstName = currentUserPersonalData?.firstName;
    this.callback.lastName = currentUserPersonalData?.lastName;
  }
  
  private setDefaultFields() {
    this.gtmLabel = CallbackLabel.ACTIVATED_BY_USER;
    this.callbackTitle = CallbackTitle.NEED_HELP;
    this.callbackTitleClass = 'page-title pb-4 text';
  }

  private setCustomFields(data) {
    switch (data?.errorCode) {
      case ErrorCode.VEHICLE_POLICY_EXIST:
        this.gtmLabel = CallbackLabel.ACTIVATED_BY_PROCESS_VEHICLE_POLICY_EXIST;
        this.callbackTitle = CallbackTitle.VEHICLE_POLICY_EXIST;
        this.callbackTitleClass = 'page-title pb-4 text error-code-title';
        break;
      case ErrorCode.VEHICLE_VIN_EXIST:
        this.gtmLabel = CallbackLabel.ACTIVATED_BY_PROCESS_VEHICLE_VIN_EXIST;
        this.callbackTitle = CallbackTitle.VEHICLE_VIN_EXIST;
        this.callbackTitleClass = 'page-title pb-4 text error-code-title';
        break;
      case ErrorCode.VEHICLE_PLATE_NUMBER_EXIST:
        this.gtmLabel = CallbackLabel.ACTIVATED_BY_PROCESS_VEHICLE_PLATE_NUMBER_EXIST;
        this.callbackTitle = CallbackTitle.VEHICLE_PLATE_NUMBER_EXIST;
        this.callbackTitleClass = 'page-title pb-4 text error-code-title';
        break;
      case ErrorCode.VEHICLE_TYPE_NOT_SUPPORTED:
        this.gtmLabel = CallbackLabel.ACTIVATED_BY_PROCESS_VEHICLE_TYPE_NOT_SUPPORTED;
        this.callbackTitle = CallbackTitle.VEHICLE_TYPE_NOT_SUPPORTED;
        this.callbackTitleClass = 'page-title pb-4 text error-code-title';
        break;
      case ErrorCode.CONTACT_NAME_OR_PESEL_INCONSISTENT:
        this.gtmLabel = CallbackLabel.ACTIVATED_BY_PROCESS_CONTACT_NAME_OR_PESEL_INCONSISTENT;
        this.callbackTitle = CallbackTitle.CONTACT_NAME_OR_PESEL_INCONSISTENT;
        this.callbackTitleClass = 'page-title pb-4 text error-code-title';
        break;
      case ErrorCode.CONTACT_ADDRESS_INCONSISTENT:
        this.gtmLabel = CallbackLabel.ACTIVATED_BY_PROCESS_CONTACT_ADDRESS_INCONSISTENT;
        this.callbackTitle = CallbackTitle.CONTACT_ADDRESS_INCONSISTENT;
        this.callbackTitleClass = 'page-title pb-4 text error-code-title';
        break;
      case ErrorCode.CONTACT_PREVIOUS_NAME_INCONSISTENT:
        this.gtmLabel = CallbackLabel.ACTIVATED_BY_PROCESS_CONTACT_PREVIOUS_NAME_INCONSISTENT;
        this.callbackTitle = CallbackTitle.CONTACT_PREVIOUS_NAME_INCONSISTENT;
        this.callbackTitleClass = 'page-title pb-4 text error-code-title';
        break;
      case ErrorCode.EXIT_POPUP_MOUSE_LEAVE_TOP_BORDER:
        this.gtmLabel = CallbackLabel.ACTIVATED_BY_PROCESS_EXIT_POPUP_MOUSE_LEAVE_TOP_BORDER;
        this.callbackTitle = CallbackTitle.EXIT_POPUP_MOUSE_LEAVE_TOP_BORDER;
        break;
    }
  }

}
