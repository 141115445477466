import { ScannerTagModel } from "@app/shared/services/gtm/tag/scanner-tag.model";
import { ScannerAction } from "@app/shared/services/gtm/model/scanner-action.model";
import { ScannerCategory } from "@app/shared/services/gtm/model/scanner-category.model";
import { ScannerLabel } from "@app/shared/services/gtm/model/scanner-label.model";

export class ScannerTagBuilder {

  private readonly tag: ScannerTagModel;

  private constructor() {
    this.tag = new ScannerTagModel();
    this.tag.event = 'scannerEvent';
  }

  public static builder(): ScannerTagBuilder {
    return new ScannerTagBuilder();
  }

  public calculationID(calculationID: string): ScannerTagBuilder {
    this.tag.calculationID = calculationID;
    return this;
  }

  public partnerID(partnerID: string): ScannerTagBuilder {
    this.tag.partnerID = partnerID ? partnerID : '695';
    return this;
  }

  public category(category: ScannerCategory): ScannerTagBuilder {
    this.tag.category = category;
    return this;
  }

  public action(action: ScannerAction): ScannerTagBuilder {
    this.tag.action = action;
    return this;
  }

  public label(label: ScannerLabel): ScannerTagBuilder {
    this.tag.label = label;
    return this;
  }

  public value(value: number): ScannerTagBuilder {
    this.tag.value = value;
    return this;
  }

  public nointeract(nointeract: number): ScannerTagBuilder {
    this.tag.nointeract = nointeract;
    return this;
  }

  public build(): ScannerTagModel {
    return this.tag;
  }

}
