import { BreadcrumbStepData } from "./breadcrumb-step-data";
import { BreadcrumbStep } from "./breadcrumb-step.enum";

export const BREADCRUMB_LABELS_SHORT_PATH: BreadcrumbStepData[] = [
  { step: BreadcrumbStep.SHORT_PATH_BASIC_DATA, label: 'Twój pojazd' },
  { step: BreadcrumbStep.SHORT_PATH_CONSENTS, label: 'Zgody' },
  { step: BreadcrumbStep.VEHICLE_OWNER_DATA, label: 'Analiza potrzeb klienta' },
];

export const BREADCRUMB_LABELS_VEHICLE: BreadcrumbStepData[] = [
  { step: BreadcrumbStep.VEHICLE_BASIC_DATA, label: 'Twój pojazd' },
  { step: BreadcrumbStep.VEHICLE_ADDITIONAL_DATA, label: 'Użytkowanie' },
  { step: BreadcrumbStep.VEHICLE_OWNER_DATA, label: 'Kierowcy' },
  { step: BreadcrumbStep.VEHICLE_CONSENTS, label: 'Zgody' },
  { step: BreadcrumbStep.VEHICLE_OFFER_PREVIEW, label: 'Analiza potrzeb klienta' },
];

export const BREADCRUMB_LABELS_OFFER: BreadcrumbStepData[] = [
  { step: BreadcrumbStep.OFFER_VARIANT, label: 'Analiza potrzeb klienta' },
  { step: BreadcrumbStep.OFFER_TOTAL_PRICE, label: 'Potwierdzenie danych' },
  { step: BreadcrumbStep.OFFER_PAYMENT, label: 'Zakup' },
];

export const BREADCRUMB_LABELS_OMNI: BreadcrumbStepData[] = [
  { step: BreadcrumbStep.OMNI_OFFER, label: 'Analiza potrzeb klienta' },
  { step: BreadcrumbStep.OMNI_SHORT_PATH, label: 'Potwierdzenie danych' },
  { step: BreadcrumbStep.OMNI_SELL, label: 'Zakup' },
];


// FLAG_1843_APK_YOUR_OFFER_VIEW
export const BREADCRUMB_LABELS_SHORT_PATH_YOUR_OFFER: BreadcrumbStepData[] = [
  { step: BreadcrumbStep.SHORT_PATH_BASIC_DATA, label: 'Twój pojazd' },
  { step: BreadcrumbStep.SHORT_PATH_CONSENTS, label: 'Zgody' },
  { step: BreadcrumbStep.VEHICLE_OWNER_DATA, label: 'Analiza potrzeb klienta' },
  { step: BreadcrumbStep.YOUR_OFFER, label: 'Twoja oferta' },
];

export const BREADCRUMB_LABELS_VEHICLE_YOUR_OFFER: BreadcrumbStepData[] = [
  { step: BreadcrumbStep.VEHICLE_BASIC_DATA, label: 'Twój pojazd' },
  { step: BreadcrumbStep.VEHICLE_ADDITIONAL_DATA, label: 'Użytkowanie' },
  { step: BreadcrumbStep.VEHICLE_OWNER_DATA, label: 'Kierowcy' },
  { step: BreadcrumbStep.VEHICLE_CONSENTS, label: 'Zgody' },
  { step: BreadcrumbStep.VEHICLE_OFFER_PREVIEW, label: 'Analiza potrzeb klienta' },
  { step: BreadcrumbStep.VEHICLE_OFFER_PREVIEW, label: 'Twoja oferta' },
];

export const BREADCRUMB_LABELS_OFFER_YOUR_OFFER: BreadcrumbStepData[] = [
  { step: BreadcrumbStep.OFFER_VARIANT, label: 'Analiza potrzeb klienta' },
  { step: BreadcrumbStep.YOUR_OFFER, label: 'Twoja oferta' },
  { step: BreadcrumbStep.OFFER_TOTAL_PRICE, label: 'Potwierdzenie danych' },
  { step: BreadcrumbStep.OFFER_PAYMENT, label: 'Zakup' },
];

export const BREADCRUMB_LABELS_OMNI_YOUR_OFFER: BreadcrumbStepData[] = [
  { step: BreadcrumbStep.OMNI_OFFER, label: 'Analiza potrzeb klienta' },
  { step: BreadcrumbStep.OMNI_YOUR_OFFER, label: 'Twoja oferta' },
  { step: BreadcrumbStep.OMNI_SHORT_PATH, label: 'Potwierdzenie danych' },
  { step: BreadcrumbStep.OMNI_SELL, label: 'Zakup' },
];
