import { TagModel } from "@app/shared/services/gtm/model/tag.model";
import { ScannerAction } from "@app/shared/services/gtm/model/scanner-action.model";
import { ScannerCategory } from "@app/shared/services/gtm/model/scanner-category.model";

export class ScannerTagModel extends TagModel {
  category: ScannerCategory;
  action: ScannerAction;
  label: string;
  value: number;
  nointeract: number;
}
