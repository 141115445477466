<div class="help-page outer-card">
    <mat-dialog-content class="container">
        <div class="col-md-12 m-t-30">
            <div>
                <div class="w-100 text text-center text-big">
                    <strong>Wprowadzone</strong> dane
                </div>
            </div>
            <div class="w-100 m-b-30">
                <p class="text text-medium align-left">
                    <strong>Ups</strong>, wygląda na to, że we wprowadzone informacje mógł wkraść się drobny błąd. <strong>Sprawdź czy wprowadzone dane są poprawne.</strong>
                </p>
                <p class="text text-small-medium align-left">
                    <strong>Czy chcesz sprawdzić poprawność</strong> swoich danych?
                </p>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-center m-0">
        <button class="go-next-button" (click)="goNext()" mat-button>
            Idź dalej
        </button>
        <button class="check-button" (click)="close()" mat-button cdkFocusInitial>
            Sprawdź
        </button>
    </mat-dialog-actions>
</div>
