import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {SharedMainModule} from './shared/shared-main.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgHttpLoaderModule} from 'ng-http-loader';
import {NgxMaskModule} from 'ngx-mask';
import {PrivilegeService} from '@app/shared/services/privilege/privilege.service';
import {LandingPageComponent} from '@app/landing-page/landing-page.component';
import {CalculateComponent} from '@app/calculate/calculate/calculate.component';
import {SessionExpiredComponent} from '@app/shared/components/session/session-expired.component';
import {ApmErrorHandler, ApmModule, ApmService} from '@elastic/apm-rum-angular';
import {ServerErrorInterceptor} from "@app/shared/interceptors/server-error/server-error.interceptor";
import {UrlParamsInterceptor} from "@app/shared/interceptors/partner/url-params-interceptor.service";
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {AppInitializerService} from "@app/shared/services/app/app-initializer.service";
import {ToastComponent, ToastService} from "@app/shared/services/toast/toast.service";
import {ConsentService} from "@app/shared/services/consent/consent.service";
import {CorrectDataDialogComponent} from "@app/landing-page/correct-data-dialog/correct-data-dialog.component";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    CorrectDataDialogComponent,
    CalculateComponent,
    SessionExpiredComponent,
    ToastComponent,
  ],
  imports: [
    ApmModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    NgxMaskModule.forRoot(),
    GoogleTagManagerModule,
    SharedMainModule,
    AppRoutingModule,
  ],
  exports: [
    RouterModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (privilegeService: PrivilegeService) => function () {
        return privilegeService.init();
      },
      deps: [PrivilegeService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (consentService: ConsentService) => function () {
        return consentService.getConsents();
      },
      deps: [ConsentService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializerService: AppInitializerService) => function () {
        return appInitializerService.init()
      },
      deps: [AppInitializerService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlParamsInterceptor,
      multi: true
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'Set from config in app.component'
    },
    {
      provide: ErrorHandler,
      useClass: ApmErrorHandler
    },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    ToastService,
    ApmService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
