import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as dayjs from "dayjs";
import {CalendarService} from "@app/shared/services/calendar/calendar.service";
import {VideoService} from "@app/shared/components/video/video.service";
import {tap} from "rxjs/operators";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {ConfigService} from "@app/shared/services/config/config.service";
import {forkJoin, Observable} from "rxjs";
import {LoggedAgentsResponse} from "@app/shared/components/video/video.model";


@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  showConsultantForm: boolean;
  showCalendarForm: boolean;
  isConsultantWorkTime: boolean;
  isConsultantAvailable: boolean;
  VIDEO_FULL_SCREEN_CLASS = 'video-full-screen';

  constructor(private calendarService: CalendarService,
              private videoService: VideoService,
              private configService: ConfigService,
              public dialogRef: MatDialogRef<any>) {
  }

  ngOnInit() {
    this.isConsultantWorkTime = this.setIsConsultantWorkTime();
    this.setIsConsultantAvailable();
  }

  onClose() {
    this.close.emit();
  }

  setIsConsultantWorkTime(): boolean {
    const currentDate = new Date();
    const currentHour = dayjs(currentDate).hour();
    const isBusinessDay = this.calendarService.isBusinessDay(currentDate);
    const isHoliday = this.calendarService.isHoliday(currentDate);

    return currentHour > 8 && currentHour < 21 && isBusinessDay && !isHoliday;
  }

  setIsConsultantAvailable() {
    const queues = this.configService.alfavoxConsultantQueue;
    const $tasks = [];

    queues.map(q => {
      $tasks.push(
        this.videoService.loggedAgents(q.queueName).pipe(
          tap(response => {
            q.availableConsultant = response.loggedAgents > 0;
          })
        )
      );
    });

    forkJoin(...$tasks).subscribe((results: LoggedAgentsResponse[]) => {
      const countAvailableAgents = results.flatMap(e => e.loggedAgents).reduce((p, c) => p + c);
      this.isConsultantAvailable = countAvailableAgents > 0;
    });
  }

  onDialogClose() {
    this.dialogRef.close();
  }

  onShowConsultantForm() {
    this.showConsultantForm = true;
    this.addFullScreenClass();
  }

  onShowCalendarForm() {
    this.showCalendarForm = true;
    this.addFullScreenClass();
  }

  onBackConsultantForm() {
    this.showConsultantForm = false;
    this.removeFullScreenClass();
  }

  onBackCalendarForm() {
    this.showCalendarForm = false;
    this.removeFullScreenClass();
  }

  addFullScreenClass() {
    if (this.dialogRef.componentInstance) {
      this.dialogRef.addPanelClass(this.VIDEO_FULL_SCREEN_CLASS);
    }
  }

  removeFullScreenClass() {
    if (this.dialogRef.componentInstance) {
      this.dialogRef.removePanelClass(this.VIDEO_FULL_SCREEN_CLASS);
    }
  }

}
