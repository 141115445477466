import {Component, OnInit} from '@angular/core';
import {CallbackModel} from '@app/shared/components/callback/callback.model';
import {CallbackService} from '@app/shared/components/callback/callback.service';
import {NgForm, NgModel} from '@angular/forms';
import {CalculationService} from '@app/calculate/calculate/calculation.service';
import {CalculationStoreService} from '@app/calculate/calculate/calculation-store.service';
import {GtmService} from '@app/shared/services/gtm/gtm.service';
import {CallbackAction} from '@app/shared/services/gtm/model/callback-action.model';
import {MatLegacyCheckboxChange as MatCheckboxChange} from '@angular/material/legacy-checkbox';
import {PersonalDataStoreModel} from "@app/shared/services/personal-data/personal-data-store.model";
import {CallbackLabel} from "@app/shared/services/gtm/model/callback-label.model";
import * as dayjs from "dayjs";
import {CalendarService} from "@app/shared/services/calendar/calendar.service";
import {ConsentService} from "@app/shared/services/consent/consent.service";
import {PolicyService} from "@app/offer/policy/policy-service";
import {OfferStep} from "@app/shared/models/step/step.model";
import {switchMap} from "rxjs/operators";
import {StepResolver} from "@app/shared/services/url/step-resolver";
import {Router} from "@angular/router";
import {Consent} from "@app/shared/services/consent/consent.model";
import {ConsentCode} from "@app/shared/services/consent/consent-code.model";
import {CallbackBusinessType} from "@app/shared/components/callback/callback-business-type.model";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-callback-form',
  templateUrl: './callback-form.component.html',
  styleUrls: ['./callback-form.component.scss', '../../../../scss/shared/dialog.scss'],
})
export class CallbackFormComponent implements OnInit {

  callback: CallbackModel;
  showFormMobile = false;
  isCallCenterWorkTime = false;
  successSend: boolean;
  gtmLabel: string;
  mainUserPersonalData: PersonalDataStoreModel;
  insurerPersonalData: PersonalDataStoreModel;
  consent: Consent;
  CALLBACK_FULL_SCREEN_CLASS = 'callback-full-screen';

  constructor(private callbackService: CallbackService,
              private consentService: ConsentService,
              private policyService: PolicyService,
              private router: Router,
              private storeService: CalculationStoreService,
              private calculationService: CalculationService,
              private gtmService: GtmService,
              private calendarService: CalendarService,
              public dialogRef: MatDialogRef<any>,) {
    this.callback = new CallbackModel();
    this.callback.businessType = CallbackBusinessType.NEW_BUSINESS;
    this.gtmLabel = CallbackLabel.ACTIVATED_BY_USER;
    this.gtmService.emitCallbackEvent(CallbackAction.POPUP_DISPLAYED, this.gtmLabel);
  }

  async ngOnInit() {
    this.mainUserPersonalData = this.storeService.getMainVehicleUserDetails();
    this.insurerPersonalData = this.storeService.getInsurerData();
    this.isCallCenterWorkTime = this.checkIsCallCenterWorkTime();
    this.consent = await this.consentService.getConsent(ConsentCode.INFO_OBLIGATION_WITH_PROFILING);

    this.autoCompleteForm();
  }

  send(callbackForm: NgForm) {
    const self = this;

    if (callbackForm.invalid) { return; }

    if (!!this.storeService.getCalculationResponse()) {
      const currentStepName = <OfferStep>StepResolver.getCurrentStepName(this.router.url);
      const mainVehicleOwnerDetails = this.storeService.getMainVehicleUserDetails();
            mainVehicleOwnerDetails.firstName = this.callback.firstName;
            mainVehicleOwnerDetails.lastName = this.callback.lastName;
            mainVehicleOwnerDetails.phoneNumber = this.callback.phoneNumber;

      this.storeService.storeMainVehicleUserDetails(mainVehicleOwnerDetails).pipe(
        switchMap(() => this.calculationService.saveCalculation()),
        switchMap(() => this.policyService.prepareSell(currentStepName))
      ).subscribe(() => {
        this.callbackSentEvent(self.gtmLabel);
        this.successSend = true;
      });
    } else {
      this.callbackService.createCallback(this.callback).subscribe(() => {
        this.callbackSentEvent(self.gtmLabel);
        this.successSend = true;
      });
    }

    this.storeService.storeSavedCallbackForm(this.callback);
  }

  onConsentChange(checkboxChange: MatCheckboxChange) {
    this.gtmService.emitCallbackEvent(checkboxChange.checked ?
      CallbackAction.FORM_FIELD_CONSENT_CHECKED : CallbackAction.FORM_FIELD_CONSENT_UNCHECKED,
      checkboxChange.source._elementRef.nativeElement.innerText);
  }

  callbackSentEvent(label: string) {
    this.gtmService.emitCallbackEvent(CallbackAction.FORM_SENT, label);
  }

  callbackFieldFocusIn(field: NgModel) {
    this.gtmService.emitCallbackEvent(CallbackAction.FORM_FIELD_ACTIVATED, field.name);
  }

  callbackFieldFocusOut(field: NgModel) {
    const isValid = field.valid;
    const isEmpty = !field.value || field.value.length === 0;

    if (isEmpty) {
      this.gtmService.emitCallbackEvent(CallbackAction.FORM_FIELD_LEFT_EMPTY, field.name);
    } else {
      if (isValid) {
        this.gtmService.emitCallbackEvent(CallbackAction.FORM_FIELD_COMPLETED_CORRECTLY, field.name);
      } else {
        const fieldName = `${field.name} - ${this.getFieldErrors(field)}`;
        this.gtmService.emitCallbackEvent(CallbackAction.FORM_FIELD_COMPLETED_INCORRECTLY, fieldName);
      }
    }
  }

  private getFieldErrors(field: NgModel): string {
    return Object.keys(field.errors)
      .map(error => {
        if (error === 'minlength') {
          return 'Minimalna liczba znaków wynosi: ' + field.errors['minlength']['requiredLength'];
        }

        return error;
      })
      .join('; ');
  }

  isNextButtonAutoFocus(): boolean {
    return this.mainUserPersonalData?.firstName ? true : false;
  }

  private checkIsCallCenterWorkTime(): boolean {
    const currentDate = new Date();
    const currentHour = dayjs(currentDate).hour();
    const isBusinessDay = this.calendarService.isBusinessDay(currentDate);
    const isHoliday = this.calendarService.isHoliday(currentDate);

    return currentHour >= 8 && currentHour <= 20 && isBusinessDay && !isHoliday;
  }

  private autoCompleteForm() {
    const currentUserPersonalData = this.insurerPersonalData ?? this.mainUserPersonalData;

    this.callback.phoneNumber = currentUserPersonalData?.phoneNumber;
    this.callback.firstName = currentUserPersonalData?.firstName;
    this.callback.lastName = currentUserPersonalData?.lastName;
  }

  addFullScreenClass() {
    if (this.dialogRef.componentInstance) {
      this.dialogRef.addPanelClass(this.CALLBACK_FULL_SCREEN_CLASS);
    }
  }

  removeFullScreenClass() {
    if (this.dialogRef.componentInstance) {
      this.dialogRef.removePanelClass(this.CALLBACK_FULL_SCREEN_CLASS);
    }
  }

  onShowFormMobile($event) {
    $event.preventDefault();
    this.showFormMobile = true;
    this.addFullScreenClass();
  }

  onDialogClose() {
    this.dialogRef.close();
  }

  onBack() {
    this.showFormMobile = false;
    this.removeFullScreenClass();
  }

}
