import {Directive, HostListener} from '@angular/core';
import {UntypedFormGroup, NgForm, ValidationErrors} from "@angular/forms";
import {ApmService} from "@elastic/apm-rum-angular";
import {CustomApmService} from "@app/shared/services/apm/custom-apm.service";
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {Flags} from "@app/shared/models/flag/flag.model";

@Directive({
    selector: 'form, input, select'
})
export class ErrorFormLogDirective {

  constructor(private ngForm: NgForm,
              private apmService: ApmService,
              private customApmService: CustomApmService,
              private featureFlagService: FeatureFlagService) {
  }

  @HostListener('submit', ['$event'])
  onSubmit(event) {
    const eventType = 'submit';
    const flagIsActive = this.featureFlagService.isActive(Flags.FLAG_1148_ERROR_VALIDATION_FORM_LOG);

    if (flagIsActive) {
      this.getFormValidationErrors(this.ngForm.form);
      this.customApmService.sendLogFormValidations(eventType);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event) {
    const controlName = event.target.name;
    const eventType = 'blur';
    const flagIsActive = this.featureFlagService.isActive(Flags.FLAG_1148_ERROR_VALIDATION_FORM_LOG);

    if (flagIsActive) {
      this.getFieldValidationErrors(this.ngForm.form, controlName);
      this.customApmService.sendLogFormValidations(eventType);
    }
  }

  getFieldValidationErrors(form: UntypedFormGroup, controlName: string) {
    const control = form.get(controlName);

    if (control) {
      const controlErrors: ValidationErrors = control.errors;
      const controlValue = control.value;

      this.setErrorsInLabelApm(controlErrors, controlName, controlValue);
    }
  }

  getFormValidationErrors(form: UntypedFormGroup) {
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      const controlErrors: ValidationErrors = control.errors;
      const controlValue = control.value;

      this.setErrorsInLabelApm(controlErrors, key, controlValue);
    });
  }

  setErrorsInLabelApm(controlErrors: ValidationErrors, controlName: string, controlValue: string) {
    const labels = {};

    if (controlErrors) {
      Object.keys(controlErrors).forEach(keyError => {
        if (controlValue) {
          labels['control_' + controlName + '_' + keyError] = controlValue;
        } else {
          labels['control_' + controlName + '_' + keyError] = 'undefined';
        }
      });

      this.apmService.apm.addLabels(labels);
    }
  }

}
