import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {CalculationStoreService} from "@app/calculate/calculate/calculation-store.service";
import {OfferStep} from "@app/shared/models/step/step.model";
import {BaseStepVisibleComponent} from "@app/shared/components/base-step-visible/base-step-visible.component";

@Component({
  selector: 'offer-number',
  templateUrl: './offer-number.component.html',
  styleUrls: ['offer-number.component.scss']
})
export class OfferNumberComponent extends BaseStepVisibleComponent {

  offerNumber: string;

  constructor(router: Router,
              private storeService: CalculationStoreService) {
    super(new Set<string>([
      OfferStep.OMNI_OFFER,
      OfferStep.OMNI_ADDITIONAL_OPTION,
      OfferStep.INSURANCE_VARIANT,
      OfferStep.INSURANCE_ADDITIONAL_OPTION,
      OfferStep.YOUR_OFFER,
      OfferStep.INSURANCE_TOTAL_PRICE,
      OfferStep.VEHICLE_REGISTRATION_DATA,
      OfferStep.VEHICLE_OWNER_ADDITIONAL_DETAILS,
      OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_DETAILS,
      OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_DETAILS,
      OfferStep.SHORT_PATH_DETAILS,
      OfferStep.VEHICLE_FIRST_CO_OWNER_ADDITIONAL_ADDRESS,
      OfferStep.VEHICLE_SECOND_CO_OWNER_ADDITIONAL_ADDRESS,
      OfferStep.CONTACT_AND_CONSENT,
      OfferStep.INCONSISTENT_UFG
    ]), router);
  }

  protected updateData(): void {
    this.offerNumber = this.storeService.getOfferNumber();
  }

}
