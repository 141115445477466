import { Injectable } from '@angular/core';
import { ConfigService } from '@app/shared/services/config/config.service';
import { CalculationStoreService } from '@app/calculate/calculate/calculation-store.service';
import { interval} from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SessionExpirationModel } from '@app/shared/services/config/session-expiration.model';
import { Step } from '@app/shared/models/step/step.model';
import {ConfigModel} from "@app/shared/services/config/config.model";

const defaultSessionExpiration: SessionExpirationModel = {
  periodSeconds: 60,
  timeoutSeconds: 60 * 60,
};

@Injectable()
export class SessionExpirationService {

  disable = false;
  config: ConfigModel;

  constructor(private configService: ConfigService,
              private calculationStoreService: CalculationStoreService,
              private dialog: MatDialog,
              private router: Router) {
    this.config = this.configService.getConfigInstant();
  }

  startCheck() {
    const sessionExpiration = this.config.sessionExpiration || defaultSessionExpiration;

    interval(sessionExpiration.periodSeconds * 1000)
      .pipe(
        filter(() =>
          !this.disable &&
          this.calculationStoreService.hasCompletedFirstStep() &&
          this.hasTimeout(sessionExpiration.timeoutSeconds)),
        switchMap(() => this.expireSession())
    ).subscribe();
  }

  private hasTimeout(timeoutSeconds) {
    const lastModificationDate = this.calculationStoreService.getLastModificationDate();
    const sessionExpirationDate = lastModificationDate.add(timeoutSeconds, 'second').toDate();
    const now = dayjs();

    return now.isAfter(sessionExpirationDate);
  }

  private expireSession(): Promise<boolean> {
    this.dialog.closeAll();
    return this.router.navigate([Step.SESSION_EXPIRED], { replaceUrl: true });
  }
}
