<div provideParentForm>
  <mat-form-field [class]="customClass"
                  hideRequiredMarker="true"
                  appearance="fill">
    <mat-label>{{ matLabelValue }}</mat-label>
    <date-input-group id="groupInputDate"
                      name="groupInputDate"
                      class="d-block mat-input-element"
                      #groupInputDate="ngModel"
                      inputmode="numeric"
                      placeholder="DD-MM-RRRR"
                      [disabled]="disabled"
                      [max]="maxDate"
                      [min]="minDate"
                      [ngModel]="modelRef[modelRefField]"
                      (ngModelChange)="inputGroupChange($event)">
    </date-input-group>
    <input type="hidden"
           id="pickerInputDate"
           name="pickerInputDate"
           #pickerInputDate="ngModel"
           required
           [ngModel]="modelRef[modelRefField]"
           (ngModelChange)="pickerDateChange($event)"
           [matDatepicker]="picker"
           [max]="maxDate"
           [min]="minDate">
    <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon matDatepickerToggleIcon svgIcon="pictogram-calendar"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker
                    [startAt]="startAt"
                    [disabled]="disabled"
                    [calendarHeaderComponent]="headerComponent">
    </mat-datepicker>
    <mat-error>
      <validation-messages [for]="groupInputDate">
        <validation-message name="datepicker-format">
          Wprowadź poprawny format daty DD-MM-RRRR
        </validation-message>
        <validation-message name="matDatepickerMin">
          Data nie może być mniejsza niż {{ minDate | date : 'dd-MM-YYYY' }}
        </validation-message>
        <validation-message name="matDatepickerMax">
          Data nie może być większa niż {{ maxDate | date : 'dd-MM-YYYY' }}
        </validation-message>
        <validation-message name="dayMax">
          Dzień nie może być większy niż 31
        </validation-message>
        <validation-message name="monthMax">
          Miesiąc nie może być większy niż 12
        </validation-message>
        <validation-message name="dayMin">
          Dzień nie może być mniejszy niż 01
        </validation-message>
        <validation-message name="monthMin">
          Miesiąc nie może być mniejszy niż 01
        </validation-message>
      </validation-messages>
    </mat-error>
  </mat-form-field>
</div>
