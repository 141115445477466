import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DatepickerHeaderComponent} from '@app/shared/components/datepicker/datepicker-header/datepicker-header.component';
import {Dayjs} from "dayjs";

@Component({
  selector: 'app-datepicker-materials',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
  exportAs: 'datepickerMaterials',
})
export class AppDatepickerMaterialsComponent implements AfterViewInit {
  headerComponent = DatepickerHeaderComponent;

  @Input() modelRef: any;
  @Input() modelRefField: string;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() startAt: Date;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() customClass: string;
  @Input() matLabelValue: string;
  @Output() emitOnChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('picker', { static: true }) picker;
  @ViewChild('groupInputDate') groupInputDate;

  ngAfterViewInit() {
    const self = this;

    this.picker.closedStream.subscribe(function () {
      self.emitOnChange.emit(null);
    });
  }

  inputGroupChange($event: Date) {
    if ($event) {
      const utcDate = new Date(Date.UTC($event.getFullYear(), $event.getMonth(), $event.getDate(), 0, 0, 0));

      this.startAt = utcDate;
      this.modelRef[this.modelRefField] = utcDate;
      this.emitOnChange.emit(null);
    }
  }

  pickerDateChange($event: Dayjs) {
    if ($event) {
      const localTimeZoneDate = $event.toDate();
      const utcDate = new Date(Date.UTC(localTimeZoneDate.getFullYear(), localTimeZoneDate.getMonth(), localTimeZoneDate.getDate(), 0, 0, 0));

      this.modelRef[this.modelRefField] = utcDate;
    }
  }

  open() {
    this.picker.open();
  }
}
