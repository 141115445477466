<div class="dialog-close-container"
     *ngIf="!!dialogRef?.componentInstance">
    <mat-icon (click)="onDialogClose()"
              svgIcon="close-purple"
              class="dialog-callback-close-icon">
    </mat-icon>
</div>
<div [ngClass]="{ 'd-block d-lg-none': !showFormMobile,
                  'd-none': showFormMobile}">
    <div class="callback-form-mobile-container">
        <div class="text">
            <div class="d-flex justify-content-center">
                <mat-icon class="callback-icon" svgIcon="call-me"></mat-icon>
            </div>
            <p class="text">
                <strong>Chcesz skontaktować się</strong>
                z&nbsp;doradcą telefonicznie?
                Zadzwoń
                <a class="purple-button mt-3" href="tel:+48224446768">
                    <mat-icon svgIcon="call-border-phone" class="call-icon"></mat-icon>
                    <span class="call-desc fs-14">22 444 67 68</span>
                </a>
            </p>
        </div>
        <div class="pt-4">
            <div class="d-flex justify-content-center">
                <mat-icon class="callback-icon" svgIcon="call-you"></mat-icon>
            </div>
            <p class="text">
                Wolisz żeby nasz <strong>doradca
                zadzwonił</strong> do Ciebie?
                <a class="purple-button mt-3 fs-14"
                   href="#"
                   (click)="onShowFormMobile($event)">Zostaw numer</a>
            </p>
        </div>
    </div>
</div>
<div *ngIf="!successSend"
     class="text mb-4 d-none d-lg-block">
    <mat-icon svgIcon="widget-phone" class="slide-phone-icon"></mat-icon>
    <p class="fs-18 mb-4"><strong>Zadzwoń</strong> lub <strong>zamów</strong> rozmowę</p>
    <p class="fs-14 m-0">Zadzwoń teraz pod numer</p>
    <p class="fs-28 m-0 mt-2"><strong>22 444 67 68</strong></p>
    <p class="fs-14 mt-4">lub <strong>zamów</strong> rozmowę telefoniczną</p>
</div>
<div class="text d-lg-none"
     *ngIf="showFormMobile && !successSend">
    <mat-icon svgIcon="widget-phone" class="slide-phone-icon"></mat-icon>
    <p class="fs-18 mb-4 mt-1"><strong>Zamów</strong> rozmowę</p>
</div>
<form id="callbackFormComponent"
      name="callbackFormComponent"
      (submit)="send(callbackFormComponent)"
      #callbackFormComponent="ngForm"
      *ngIf="!successSend"
      [ngClass]="{ 'callback-form-submitted': !!callbackFormComponent.submitted,
                   'd-none d-lg-block': !showFormMobile}">
    <div class="col-md-12">
        <div>
            <div class="justify-content-center">
                <div class="col-md-12">
                    <div class="form-field">
                        <app-first-name id="callback-form-first-name"
                                        [modelRef]="callback"
                                        [requiredField]="true"
                                        [redStar]="true"
                                        [autoFocus]="false"
                                        (focusEvent)="callbackFieldFocusIn($event)"
                                        (focusoutEvent)="callbackFieldFocusOut($event)">
                        </app-first-name>
                    </div>
                    <div class="form-field">
                        <app-last-name id="callback-form-last-name"
                                       [modelRef]="callback"
                                       [requiredField]="true"
                                       [redStar]="true"
                                       (focusEvent)="callbackFieldFocusIn($event)"
                                       (focusoutEvent)="callbackFieldFocusOut($event)">
                        </app-last-name>
                    </div>
                    <div class="form-field">
                        <app-phone id="callback-form-phone"
                                   [modelRef]="callback"
                                   [requiredField]="true"
                                   [redStar]="true"
                                   (focusEvent)="callbackFieldFocusIn($event)"
                                   (focusoutEvent)="callbackFieldFocusOut($event)">
                        </app-phone>
                    </div>
                    <mat-checkbox id="callback-form-agreement"
                                  name="callback-form-agreement"
                                  class="m-b-15"
                                  #agreement="ngModel"
                                  [(ngModel)]="callback.agreement"
                                  (change)="onConsentChange($event)"
                                  [required]="true">
                        <span *ngIf="consent" [innerHTML]="consent.text"></span>
                    </mat-checkbox>
                </div>
            </div>
            <div *ngIf="callbackFormComponent.invalid && callbackFormComponent.submitted"
                class="row pb-2 invalid-submitted-form-text invalid-help-page justify-content-center">
                Wypełnij wymagane pola!
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="justify-content-center d-flex col-md-12">
            <button #submitButton class="next-button" mat-button [customAutoFocus]="isNextButtonAutoFocus()">
                WYŚLIJ
                <mat-icon svgIcon="arrow-right"></mat-icon>
            </button>
        </div>
        <div class="justify-content-center d-flex d-lg-none col-md-12">
            <a class="callback-form-back-button mt-4" (click)="onBack()">
                Wróć
            </a>
        </div>
    </div>
</form>
<div *ngIf="successSend" class="text w-100 mb-4">
    <mat-icon svgIcon="call-success"></mat-icon>
    <p class="fs-18">
        <strong>Twoje zgłoszenie</strong> zostało <strong>przyjęte</strong>.
    </p>
    <p>
        Wkrótce zadzwonimy do Ciebie.
    </p>
</div>
