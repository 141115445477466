export enum Flags {
  FLAG_1945_REGISTER_DECLARATION_PATH = 'FLAG_1945_REGISTER_DECLARATION_PATH',
  FLAG_934_EXIT_POPUP_MOUSE_LEAVE_TOP_BORDER = 'FLAG_934_EXIT_POPUP_MOUSE_LEAVE_TOP_BORDER',
  FLAG_1007_NK_SMS_AUTHORIZATION = 'FLAG_1007_NK_SMS_AUTHORIZATION',
  FLAG_1148_ERROR_VALIDATION_FORM_LOG = 'FLAG_1148_ERROR_VALIDATION_FORM_LOG',
  FLAG_1497_SHOW_DYNAMIC_DRIVER_LICENSE = 'FLAG_1497_SHOW_DYNAMIC_DRIVER_LICENSE',
  FLAG_1552_ACCEPT_POLICY = "FLAG_1552_ACCEPT_POLICY",
  FLAG_1749_LIVECHAT = "FLAG_1749_LIVECHAT",
  FLAG_1774_ONETRUST = "FLAG_1774_ONETRUST",
  FLAG_1801_APK = 'FLAG_1801_APK',
  FLAG_1808_CORRECT_LICENSE_PLATE = 'FLAG_1808_CORRECT_LICENSE_PLATE',
  FLAG_1809_CORRECT_BIRTH_DATE = 'FLAG_1809_CORRECT_BIRTH_DATE',
  FLAG_1843_APK_YOUR_OFFER_VIEW = 'FLAG_1843_APK_YOUR_OFFER_VIEW',
  FLAG_1874_SALESMANAGO = 'FLAG_1874_SALESMANAGO',
  FLAG_1894_NEW_VARIANTS_CONFIGURATION = 'FLAG_1894_NEW_VARIANTS_CONFIGURATION',
  FLAG_1914_RIBBON_NEW_REPLACEMENT_CAR = 'FLAG_1914_RIBBON_NEW_REPLACEMENT_CAR',
  FLAG_1953_SHOW_BUTTON_DESCRIPTION = 'FLAG_1953_SHOW_BUTTON_DESCRIPTION',
  FLAG_1962_HIDE_VEHICLE_SERVICE_BOOK = 'FLAG_1962_HIDE_VEHICLE_SERVICE_BOOK',
  FLAG_1963_HIDE_VEHICLE_MODIFIED = 'FLAG_1963_HIDE_VEHICLE_MODIFIED',
  FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK = 'FLAG_2022_WIDGET_LIVECHAT_ALFAVOX_CALLBACK',
  FLAG_2039_VIDEO = 'FLAG_2039_VIDEO',
  FLAG_2071_OWU_IPID = 'FLAG_2071_OWU_IPID',
  FLAG_2145_AUTO_ASSISTANCE_NEW_DISTANCE = 'FLAG_2145_AUTO_ASSISTANCE_NEW_DISTANCE',
}
