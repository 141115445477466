import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig} from '@angular/material/legacy-snack-bar';
import {Component, Inject, Injectable, ViewEncapsulation} from '@angular/core';

enum ToastMode {
  INFO,
  ERROR
}

@Injectable()
export class ToastService {

  constructor(public snackBar: MatSnackBar) {
  }

  info(message: string) {
    this.open(message, ToastMode.INFO);
  }

  error(message: string) {
    this.open(message, ToastMode.ERROR);
  }

  private open(_message: string, _mode: ToastMode) {
    const config = new MatSnackBarConfig();

    config.duration = 8000;
    config.horizontalPosition = 'right';
    config.verticalPosition = 'bottom';
    config.panelClass = _mode === ToastMode.INFO ? ['toast', 'toast-info'] : ['toast', 'toast-error'];

    config.data = {
      message: _message
    };

    this.snackBar.openFromComponent(ToastComponent, config);
  }

}

@Component({
  selector: 'toast',
  templateUrl: 'toast.html',
  styleUrls: ['toast.service.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToastComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

}
