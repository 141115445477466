import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const CUSTOM_ICONS = [
  'add',
  'arrow-left',
  'arrow-right',
  'chat',
  'check-circle',
  'check-circle-pink',
  'check-circle-purple',
  'close',
  'close-purple',
  'ellipse',
  'edit',
  'help-circle',
  'phone',
  'remove',
  'vehicle',
  'omni-vehicle',
  'omni-pic-cover',
  'pictogram-assistance',
  'pictogram-back-to-health',
  'pictogram-ecocyclist',
  'pictogram-help',
  'pictogram-help-after-accident',
  'pictogram-keys',
  'pictogram-navi-expert',
  'pictogram-nnw',
  'pictogram-protected-discount',
  'pictogram-smart-casco',
  'pictogram-theft',
  'pictogram-window',
  'pictogram-ac-orginal-staff',
  'pictogram-ac-repair-shop',
  'pictogram-ac-accident',
  'pictogram-oc',
  'pictogram-protected-discount',
  'pictogram-ring-help',
  'pictogram-green-card',
  'pictogram-replacement-car',
  'pictogram-new-ac-repair-shop',
  'pictogram-new-ac-aso',
  'pictogram-new-ac-estimate',
  'pictogram-calendar',
  'widget-phone',
  'widget-chat',
  'widget-video',
  'widget-video-error',
  'widget-consultant-not-available',
  'widget-close',
  'widget-mobile-start',
  'video-consultant',
  'video-calendar',
  'call-me',
  'call-you',
  'call-border-phone',
  'call-success',
];

@Injectable({
  providedIn: 'root',
})
export class IconLoaderService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  registerCustomIcons(): void {
    CUSTOM_ICONS.forEach((iconName) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/images/${iconName}.svg`
        )
      );
    });
  }
}
