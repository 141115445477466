import {Inject, Injectable} from '@angular/core';
import {FeatureFlagService} from "@app/shared/services/feature-flag/feature-flag.service";
import {DOCUMENT} from "@angular/common";
import {Flags} from "@app/shared/models/flag/flag.model";
import {ConfigService} from "@app/shared/services/config/config.service";

@Injectable({
  providedIn: 'root'
})
export class SalesManagoService {
  constructor(private featureFlagService: FeatureFlagService,
              private configService: ConfigService,
              @Inject(DOCUMENT) private document: Document) {
  }

  loadJsScript() {
    const config = this.configService.getConfigInstant();
    const head = window.document.getElementsByTagName('head')[0];
    const smScript = window.document.createElement('script');
          smScript.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'app2.salesmanago.pl/static/sm.js';
          smScript.setAttribute('type', 'text/javascript');
          smScript.setAttribute('async', 'true');

    window['SalesmanagoObject'] = 'sm';
    window['sm'] = window['sm'] || function () {( window['sm'].q = window['sm'].q || [] ).push(arguments)};
    window['_smid'] = config.client.smId;

    if (config.client.smApp) {
      window['_smapp'] = config.client.smApp;
    }

    head.insertBefore(smScript, head.firstChild);

    return smScript;
  }

  async init() {
    if (this.featureFlagService.isActive(Flags.FLAG_1874_SALESMANAGO)) {
      return this.loadJsScript();
    }
  }

}
